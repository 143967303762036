<template>
    <v-container class="page-container" fill-height fluid grid-list-xl
    >
        <v-layout wrap id="configuration">
            <h1 class="page-title text-xs-left mb-3 w-100">{{header_title}}</h1>
            <div class="flex-display flex-row flex-justify-end mb-3 w-100">
                <button class="btn add-new" @click="showPackageModal('add',null)"><i class="v-icon mdi mdi-plus"></i>{{add_button_text}}</button>
            </div>
            <div class="session-package w-100" v-if="isReady">
                <template v-if="packages.length > 0">
                    <div class="item-container"
                         v-for="p in packages"
                         :key="p.id"
                    >
                        <p class="item-text">{{p.title}}</p>
                        <div class="item-actions">
                            <button class="btn-edit" @click="editSessionPackage(p)">Edit</button>
                            <button class="btn-delete ml-2" @click="showDeleteConfirmation(p)">Delete</button>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <p class="text-center w-100 py-3">No package found! please add a new package</p>
                </template>
            </div>

            <!-- Add Session Package -->
            <add-session-package v-if="packageModalDialog"
                                 :visible="packageModalDialog"
                                 :action="action"
                                 :sessionTypes="sessionTypes"
                                 :psychologists="psychologists"
                                 :value="selectedPackage"
                                 v-on:submitPackage="addSessionPackage"
                                 v-on:hide="hidePackageModal"
            >
            </add-session-package>
            <!-- delete dialog -->
            <confirmation v-if="confirmationDialog" :visible="confirmationDialog">
                <template slot="title">
                    Are you sure?
                </template>
                <template slot="desc">
                    This session package will be removed. This action cannot be undone.
                </template>
                <template slot="actions">
                    <a class="cancel-model" @click="hideDeleteConfirmation">Cancel</a>
                    <button class="btn modal-action-button confirmation-delete" @click="removeSessionPackage">Delete</button>
                </template>
            </confirmation>
        </v-layout>

    </v-container>
</template>

<script>
    import Confirmation from "../components/common/Confirmation";
    import addSessionPackage from "../components/configuration/addSessionPackage";
    export default {
        components: {addSessionPackage, Confirmation},
        data: () => ({
            header_title: 'Session Packages',
            add_button_text: 'New Package',
            action: 'add',
            isReady: false,
            disableActions: false,
            packageModalDialog: false,
            confirmationDialog: false,
            packages: [],
            psychologists: [],
            sessionTypes: [],
            selectedPackage: null,
            defaultPsychologists: [
                {
                    id: null,
                    name: 'All Psychologists'
                }
            ],
        }),
        created() {
            this.getSessionPackages();
        },
        methods:{
            showPackageModal() {
                this.packageModalDialog = true;
            },
            hidePackageModal() {
                this.action = 'add';
                this.selectedPackage = null;
                this.packageModalDialog = false;
            },
            showDeleteConfirmation(p) {
                this.selectedPackage = p;
                this.confirmationDialog = true;
            },
            hideDeleteConfirmation() {
                this.confirmationDialog = false;
            },
            getSessionPackages() {
                let self = this;
                let loader = this.$loading.show();
                self.disableActions = true;
                self.$http.get(this.url+'session/packages?additional=psychologists,session_types', {
                }).then(function (response) {
                    loader.hide();
                    self.isReady = true;
                    self.disableActions = false;
                    self.packages = response.data.packages;
                    self.sessionTypes = response.data.session_types;
                    self.psychologists = self.defaultPsychologists.concat(response.data.psychologists);
                }).catch(function (error) {
                    loader.hide();
                    self.isReady = true;
                    self.disableActions = false;
                    self.$toastr('error', error.response.data.message, 'Error');
                })
            },
            editSessionPackage(p) {
                this.action = 'update';
                p.discount = parseInt(p.discount);
                this.selectedPackage = p;
                this.showPackageModal();
            },
            addSessionPackage(data, action) {
                const self = this;
                let loader = self.$loading.show();
                data.action = action;
                self.$http.put(this.url+'session/package', data).then(function (response) {
                    loader.hide();
                    self.hidePackageModal();
                    self.$toastr('success', response.data.message);
                    self.getSessionPackages();
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message);
                });
            },
            removeSessionPackage() {
                let self = this;
                let loader = this.$loading.show();
                self.hideDeleteConfirmation();
                self.$http.delete(this.url+'session/package', {
                    data: {
                        id: self.selectedPackage.id
                    }
                }).then(function (response) {
                    loader.hide();
                    self.selectedPackage = null;
                    self.$toastr('success', response.data.message);
                    self.getSessionPackages();
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error',error.response.data.message);
                })
            }
        }
    }
</script>
